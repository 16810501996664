
import { eoRendererMixin, metaDataMixin } from '~/mixins'

export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
  ],
  layout: 'campaign',
  scrollToTop: false,
  async asyncData ({ $api, $config, store, route: { path, params: { slug }}, error }) {
    const {
      data: {
        items: campaign = [],
      } = {},
    } = await $api.campaigns.getOneBySlug(slug, {
      domain: $config.preprEnvironmentDomainEo,
    })

    // Check publication if exists
    if (!campaign || !campaign.length) {
      error({
        context: `Couldn't create campaign.`,
        message: '(404) Campagnes niet gevonden',
        severity: 'info',
        statusCode: 404,
      })
      return false
    }

    // Set breadcrumbs
    store.dispatch('breadcrumbs/set', {
      path,
      eoRoot: false,
      floating: true,
      width: 10,
      items: [
        {
          label: campaign[0].title,
        },
      ],
    })

    return campaign[0]
  },
  data () {
    return {
      articles: [],
      callToAction: null,
      changedOn: null,
      description: [],
      endDate: null,
      environmentId: null,
      faq: [],
      hero: null,
      heroVideo: null,
      heroVideoStill: null,
      id: null,
      lineUp: [],
      location: null,
      program: [],
      publishOn: null,
      seo: null,
      slug: null,
      social: null,
      startDate: null,
      subtitle: null,
      terms: [],
      title: null,
      topic: null,

      specialTeasers: [],
    }
  },
  async fetch () {
    const {
      data: {
        items: articles = [],
      } = {},
    } = await this.$api.articles.getAll({
      offset: this.articles.length,
      limit: 4,
      campaign: this.id,
    })

    this.articles.push(...articles)
  },
}
